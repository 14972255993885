<template>
	<div class="container">
		<div class="content" :class="!baselList.length?'white':''">
			<ul class="train-ul">
				<li class="flex flex-between" v-for="item in baselList" :key="item.article_id" @click="openBlank('TrainingDetail',item.article_id)">
					<div class="ft-thumb">
						<el-image :src="item.thumb" fit="cover">
							<div slot="error" class="image-slot">
								<img src="@/assets/images/default.png" alt="">
							</div>
						</el-image>
					</div>
					<div class="rt-text">
						<h3 class="title">
							<el-link :underline="false" :title="item.title">{{item.title}}</el-link>
						</h3>
						<p>{{item.content}}</p>
						<div class="view-more">查看详情</div>
					</div>
				</li>
				
			</ul>
			<div class="paging">
				<el-pagination 
				background 
				:current-page="page" 
				@current-change="getBaselList"
				:hide-on-single-page="true"
				layout="prev, pager, next" 
				:page-count="totalPage">
				</el-pagination>
			</div>
			<el-empty description="暂无数据" v-if="!baselList.length"></el-empty>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				page:1,
				totalPage:0,
				baselList:[],
			}
		},
		created() {
			this.getBaselList();
		},
		methods: {
			// 获取实训基地数据
			getBaselList() {
				this.$http.get('/api/web/news/getSXNews?page='+this.page)
					.then((res) => {
						this.baselList = res.data.list
						this.totalPage=res.data.totalPage;
					})
			},
			openBlank(name,id){
				let routeUrl = this.$router.resolve({
          name,
					query:{id,type:'sx'}
				});
				window.open(routeUrl.href, '_blank');
			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		padding: 30px;
		background-color: #F5F5F5;

		.content {
			width: var(--container-width);
			margin: 0 auto;
			min-height: calc(100vh - 490px);

			.train-ul {
				width: 100%;
				padding: 0;
				margin: 0;

				li {
					width: 100%;
					min-height: 375px;
					padding: 20px;
					background: white;
					border-radius: 20px;
					margin-bottom: 30px;

					.ft-thumb {
						width: 470px;
						height: 340px;
						background: #EFF3F5;
						border-radius: 10px;
						overflow: hidden;

						.el-image {
							display: block;
							width: 100%;
							height: 340px;
						}
					}
					.rt-text{
						position: relative;
						width: 745px;
						.title{
							font-size: 24px;
							font-weight: bold;
							color: #333333;
							line-height: 32px;
							text-align: left;
							margin: 0;
							.el-link{
								font-size: 24px;
							}
						}
						p{
							font-size: 16px;
							color: #666666;
							line-height: 32px;
							text-align: left;
						}
						.view-more{
							position: absolute;
							bottom: 10px;
							width: 140px;
							height: 48px;
							font-size: 18px;
							line-height: 48px;
							font-weight: bold;
							color: #FFFFFF;
							background: #D71A18;
							border-radius: 24px;
							cursor: pointer;
						}
					}
				}
				li:last-child{
					margin-bottom: 0;
				}
			}
			.paging{
				width: 100%;
				margin-top: 20px;
				
				/deep/.el-pager li{
					font-size: 16px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					font-weight: normal;
					background-color: white;
				}
				/deep/.el-pager li:not(.disabled).active {
				    background-color: #d71a18;
				    color: #fff;
				}
				/deep/.btn-next,/deep/.btn-prev{
					background-color: white;
					font-size: 16px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					font-weight: normal;
				}
			}
		}
		.white{background-color: #fff;}
	}
</style>
